<template>
	<el-dialog title="添加照片" :close-on-click-modal="false" :visible.sync="subVisible" width="1210px">
		<el-card body-style="padding-left:0;padding-bottom: 0;">
			<el-form :model="dataForm" ref="dataForm" label-width="80px" :inline="true">
				<el-row>
					<el-col style="width: 420px;margin-bottom: 10px;margin-left: -520px">
						<el-form-item label="登记时间">
							<el-date-picker v-model="recContentTime" value-format="yyyy-MM-dd" style="width:300px"
								type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col style="width: 80px;">
						<el-button v-preventReClick type="primary" size="medium" @click="selGetDataList()">检索</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<el-row style="margin-top: 20px;">
			<el-col :span="12">
				<el-button type="primary" size="medium" @click="dataSubmit()"
					:disabled="selectItems.length == 0">确定</el-button>
			</el-col>
			<el-col :span="12" style="text-align: right;">
				<el-pagination background layout="prev, pager, next" :pager-count="5" @current-change="currentChangeHandle"
					:current-page="page" :page-size="limit" :total="totalCount">
				</el-pagination>
			</el-col>
		</el-row>
		<div class="card-container" style="height: 450px;padding-top: 10px;">
			<div class="imgList" v-for="(row, rowIndex) in cardRows(dataList)" :key="rowIndex">
				<el-row>
					<div class="img-item" v-for="(item, colIndex) in row" :key="item.recNo"
						@click="clickCard(item, rowIndex, colIndex)">
						<el-col style="width: 180px;margin-left: 10px;">
							<el-card :body-style="{ padding: '0px' }" style="margin-bottom: 10px;height: 195px;"
								:class="{ active: checkActive(item) }">
								<el-image :src="item.photoPathUri" fit="scale-down" class="image"
									style="height: 120px;width: 168px;margin: 5px;display: block;"></el-image>
								<div style="padding: 2px 10px;">
									<div style="padding-bottom: 10px;height:50px;overflow: hidden;">
										<span>{{ item.photoContent }}</span>
									</div>
								</div>
							</el-card>
						</el-col>
					</div>
				</el-row>
			</div>
		</div>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			pageSpan: 12,
			subVisible: true,
			dataList: [],
			recContentTime: [],
			dataForm: {
				workNo: $common.getItem("workNo"),
				beginTime: "",
				endTime: ""
			},
			limit: 12,
			page: 1,
			totalCount: 0,
			photoViewCol: 6,
			selectItems: [],
		};
	},
	methods: {
		cardRows(photoList) {
			const rows = [];
			for (let i = 0; i < photoList.length; i += this.photoViewCol) {
				rows.push(photoList.slice(i, i + this.photoViewCol));
			}
			return rows;
		},
		checkActive(item) {
			let act = this.selectItems.some(function (element) {
				return element.recNo == item.recNo
			})
			return act
		},
		clickCard(item, rowIndex, colIndex) {
			const idx = rowIndex * this.photoViewCol + colIndex
			let index = this.selectItems.indexOf(item)
			if (index == -1) {
				this.selectItems.push(item)
			} else {
				this.selectItems.splice(index, 1)[0];
			}
		},
		init(logDate) {
			this.dataForm.beginTime = logDate
			this.dataForm.endTime = logDate
			this.$set(this.recContentTime, 0, logDate ? logDate : '')
			this.$set(this.recContentTime, 1, logDate ? logDate : '')
			this.$nextTick(() => {
				this.subVisible = true;
			})
			this.getDataList();
		},
		selGetDataList() {
			this.page = 1;
			this.getDataList();
		},
		resetFormData() {
			this.recContentTime = [];
		},
		currentChangeHandle(val) {
			this.page = val
			this.getDataList()
		},
		getDataList() {
			if (this.recContentTime == null) {
				this.recContentTime = ["", ""];
			}
			this.$http({
				url: this.$store.state.httpUrl + "/business/qualityprogressphotoinfo/list",
				method: "get",
				params: {
					limit: this.limit,
					page: this.page,
					beginTime: this.recContentTime[0] ? this.recContentTime[0] : '',
					endTime: this.recContentTime[1] ? this.recContentTime[1] : '',
					workNo: $common.getItem("workNo"),
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body.records;
					this.totalCount = data.body.totalCount;
				}
			});
		},
		dataSubmit() {
			this.$emit('selectItems', this.selectItems)
		}
	}
};
</script>

<style lang="scss" scoped="scoped">
.active {
	border: 1px solid #03a70b;
	-webkit-transform: rotate(0deg);
}

.active::before {
	content: "";
	position: absolute;
	right: 0;
	bottom: 0;
	border: 12px solid #03a70b;
	border-top-color: transparent;
	border-left-color: transparent;
}

.active::after {
	content: "";
	display: block;
	width: 5px;
	height: 10px;
	position: absolute;
	right: 4px;
	bottom: 5px;
	border: 1px solid #fff;
	border-top-color: transparent;
	border-left-color: transparent;
	transform: rotate(45deg);
	cursor: pointer;
}

.el-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.el-card .el-image {
	display: inline-block;
	text-align: center;
}
</style>
